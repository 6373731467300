import React, {ChangeEvent, useContext, useEffect, useMemo, useRef, useState} from 'react';
import './App.scss';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import {useFieldArray, useForm, useFormContext} from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import {
    CheckBox,
    CheckBoxes,
    DateInput,
    DateTimeInput,
    DecimalInput,
    FormField,
    FormGroup,
    IntegerInput,
    MyForm,
    RadioBoxes,
    Select,
    Textarea,
    TextUppercase
} from './forms';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import get from 'lodash/get';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import template from './assets/damage_template.jpg';
import {useDraggable} from './draggable';
import {Accordion, AccordionContext, Modal, OverlayTrigger, Popover, ProgressBar, Spinner} from 'react-bootstrap';
import useAxios from 'axios-hooks';
import {FieldDef, FormDef, HasFields, TabDef} from './types';
import {useNavigate, useParams} from 'react-router-dom';
import {Signature, SignatureRef} from './signature';
import {LICENCE_PLATE_VALIDATIONS, LicencePlateCountry} from './licence-plate-validations';
import {axios} from './App';
import {blobToBase64, getCurrentPosition, uuidv4} from './utils';
import Compressor from 'compressorjs';
import {db, ProtocolData} from './db'
import {AccordionEventKey} from 'react-bootstrap/AccordionContext'
import {I18nContextProps, useI18n} from './i18n'
import {AxiosRequestConfig, AxiosRequestHeaders} from 'axios';
import GeoLocationContextProvider, {useGeoLocation} from './geo-location';
import {md5} from './md5';

const getDamageShape = (f: FieldDef) => ({
    damages: yup.array().of(yup.object().shape({
        kind: yup.string().required({key: 'validationError.damage.kind'}),
        notes: yup.string().required({key: 'validationError.damage.notes'}),
        images: yup.array().of(yup.object().shape({uuid: yup.string().required()})).required({key: 'validationError.damage.images'}),
        part: !!f.damageParts && f.damageParts.length > 0
            ? yup.string().required({key: 'validationError.damage.part'})
            : yup.string(),
    })).required({key: 'validationError.damage.required'})
})

const integerCheck = (errMsg: string) => yup.number().transform((v, origVal) => !origVal ? undefined : /^[1-9][0-9]*$/.test(origVal) ? v : NaN).integer().positive(errMsg).typeError({key: errMsg})

function getReqValidation(f: FieldDef, i18n: I18nContextProps) {
    const label = i18n.getEffectiveLabel(f.label, 'Fields.' + f.name)
    if (f.type === "damages") {
        return yup.object().shape(getDamageShape(f)).required()
    } else if (f.type === "upload") {
        return yup.object().shape({
            images: yup.array().of(yup.object().shape({
                uuid: yup.string().required()
            })).required({key: 'validationError.imageRequired'})
        }).required()
    } else if (f.name === "milage") {
        return integerCheck('validationError.invalidMilage').required().moreThan(yup.ref('milageStart'), {key:'validationError.milageMoreThan'}).test({
            name: 'milageTest', exclusive: false, params: {},
            test: function (value: any) {
                const {path, parent, createError} = this
                const distMin = (parent.distance || 0) * 0.9
                //const distMax = 10 + ((parent.distance || 0) * 2.0)
                const eff = value - (parent.milageStart || 0)
                if (eff < distMin/* || eff > distMax*/) {
                    return createError({
                        path,
                        message: {key: 'validationError.milageTooHigh', params: {distance: parent.distance}}
                    })
                }
                return true
            }
        })
    } else if (f.name === "licencePlate") {
        return yup.string().required().test({
            name: 'licencePlateTest', exclusive: false, params: {},
            test: function (value: any) {
                const { path, parent, createError } = this
                const c: LicencePlateCountry = parent.licencePlateCountry || 'D'
                if (!value?.match(LICENCE_PLATE_VALIDATIONS[c])) {
                    return createError({path, message: {key: 'validationError.invalidLicencePlate', params: {country: c}}})
                }
                return true
            }
        })
    } else if (f.type === "checkbox") {
        return yup.bool().oneOf([true], {key: 'validationError.required', params: {field: label}})
    } else if ((f.type === "date" || f.type === "datetime") && f.minDate) {
        return yup.date().test({
            name: 'minDateTest', exclusive: false, params: {},
            test: function (value: any) {
                const {path, parent, createError} = this
                const minDate = new Date(parent[f.minDate!])
                if (new Date(value).getTime() <= (minDate.getTime() + 300000)) {
                    return createError({
                        path,
                        message: {key: 'validationError.minDate', params: {field: label, minDate: minDate.toLocaleString(i18n.locale, {
                                    weekday: 'short', year: 'numeric', month: '2-digit', day: '2-digit', hour:'2-digit', minute:'2-digit',
                                })}}
                    })
                }
                return true
            }
        })
    } else {
        let b = f.type === "integer" ? integerCheck(f.errorMessage || 'validationError.invalidNumber') : yup.string();
        return b.required()
    }
}

const FileUploadContext = React.createContext<any>(null)

export const useFileUploadContext = () => useContext(FileUploadContext)

interface UploadedFile {
    uuid: string, blob: Blob
}

export function FileUploadContextProvider({uuid: protocolUuid, admin, children}: any) {
    const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([])
    const [progress, setProgress] = useState<string>()
    const [uploading, setUploading] = useState(false)

    const sizes = useRef<any>({})

    useEffect(() => {
        db.protocols.get(protocolUuid).then(p => {
            setUploadedFiles(p?.files.map(f => ({uuid: f.uuid, blob: new Blob([f.blob], {type: f.type})})) || [])
        })
    }, [protocolUuid])

    const updateSizes = () => {
        const total: number = Object.values(sizes.current).map((f:any) => f.total).reduce((sum: number, num: number) => sum + num, 0)
        const loaded: number = Object.values(sizes.current).map((f:any) => f.loaded).reduce((sum: number, num: number) => sum + num, 0)
        setProgress(total > 0 && loaded > 0 ?( Math.min(100, loaded / total * 100).toFixed(0)) : undefined)
    }

    const fileContext = {
        protocolUuid,
        uploadedFiles,
        ready: !uploading,
        progress,
        getUrlFor: (uuid: string): string | undefined => {
            const f = uploadedFiles.find(f => f.uuid === uuid)
            return f ? URL.createObjectURL(f.blob) || `${process.env.REACT_APP_API_BASE_URL}/protocols/${protocolUuid}/files/${uuid}` : undefined
        },
        uploadFiles(): Promise<boolean> {
            const url = admin ? `/admin/protocols/files` : `/protocols/${protocolUuid}/fileupload`
            const headers: AxiosRequestHeaders = admin ? {'Authorization': `Bearer ${protocolUuid}`} : {}

            return Promise.all(uploadedFiles.map(f => {
                const fd = new FormData()
                fd.append("file", f.blob)
                fd.append("uuid", f.uuid)

                sizes.current[f.uuid] = {total: f.blob.size, loaded: 0}
                updateSizes()
                setUploading(true)
                return axios.post(url, fd, {
                    headers,
                    onUploadProgress: progressEvent => {
                        //sizes.current[f.uuid].total = progressEvent.total
                        sizes.current[f.uuid].loaded = progressEvent.loaded
                        updateSizes()
                    }
                })
            })).then(() => {
                setUploading(false)
                return true
            }).catch(_ => {
                setUploading(false)
                return false
            })
        },
        removeFile(uuid: string) {
            setUploadedFiles(prevState => prevState.filter(f => f.uuid !== uuid))
        },
        addFile(file: File): Promise<{dataUrl: string, file: File, uuid: string}> {
            return new Promise(async (resolve, reject) => {
                //const geoPos = await getCurrentPosition()
                new Compressor(file, {
                    strict: false,
                    quality: 0.7,
                    maxWidth: 1920, maxHeight: 1920,
                    // drew(context, canvas) {
                    //     context.fillStyle = '#0f0';
                    //     context.font = '30px sans-serif';
                    //     context.fillText(`${new Date().toISOString()}`, 20, canvas.height - 75);
                    //     geoPos?.coords && context.fillText(`@ ${geoPos.coords.latitude || -1} / ${geoPos.coords.longitude || -1}`, 20, canvas.height - 30);
                    // },
                    success(result) {
                        const uuid = uuidv4()
                        const f: UploadedFile = {uuid, blob: result}
                        setUploadedFiles(prevState => [...prevState, f])
                        resolve({file, dataUrl: URL.createObjectURL(result), uuid})
                    },
                    error(err) {
                        console.log("Image compressor error", err.message);
                        reject(err)
                    },
                })
            })
        }
    }
    return (
        <FileUploadContext.Provider value={fileContext}>
            {children}
        </FileUploadContext.Provider>
    )
}

function ProtocolScreen({admin} : {admin: boolean}) {
    const {uuid} = useParams<{uuid:string}>()

    const i18n = useI18n();
    const {locale, setAvailableLocales, addLabels} = i18n

    const templateConfig: AxiosRequestConfig = admin ? {url: `/admin/protocols/template?lang=${locale}`, headers: {'Authorization': `Bearer ${uuid}`}} : {url: `/protocols/${uuid}/template?lang=${locale}`}
    const [{ data:templateData, loading:templateLoading, error:templateError }] = useAxios( templateConfig)

    const [schema, setSchema] = useState<any>()

    const [data, setData] = useState<ProtocolData>()

    const navigate = useNavigate()

    useEffect(() => {
        if (templateLoading || !templateError) return
        if (templateError.response?.status === 400) {
            // already submitted, redirect...
            navigate(`/protocols/${uuid}/done?lang=${locale}&appUpload=${templateError.response.data?.message === 'Waiting for app upload'}`)
        }
    }, [templateLoading, templateError, navigate, uuid, locale])

    useEffect(() => {
        if (!templateLoading) {
            if (templateData) {
                const validations: any = {}
                const def: FormDef = templateData
                setAvailableLocales(def.languages)
                const fieldLabels: { [key: string]: string } = {}
                def.tabs.forEach(t => t.fields!.forEach(f => {
                    if (f.required && !f.readonly) {
                        validations[f.name] = getReqValidation(f, i18n)
                        if (f.label) {
                            fieldLabels[f.name] = f.label
                        }
                    }
                    if (f.fields) {
                        f.fields.forEach(ff => {
                            if (ff.required && !ff.readonly) {
                                const base: any = (ff.type === "damages" || ff.type === "upload") ? yup.object() : ff.type === "integer" ? yup.number() : yup.string()
                                validations[ff.name] = base.when(f.name, {
                                    is: f.showFieldsIf,
                                    then: getReqValidation(ff, i18n)
                                })
                                if (ff.label) {
                                    fieldLabels[ff.name] = ff.label
                                }
                            }
                        })
                    }
                    if (f.radioOptions) {
                        f.radioOptions.forEach(o => {
                            o.fields?.forEach(ff => {
                                if (ff.required && !f.readonly) {
                                    const base: any = (ff.type === "damages" || ff.type === "upload") ? yup.object() : ff.type === "integer" ? yup.number() : yup.string()
                                    validations[ff.name] = base.when(f.name, {
                                        is: o.value,
                                        then: getReqValidation(ff, i18n)
                                    })
                                    if (ff.label) {
                                        fieldLabels[ff.name] = ff.label
                                    }
                                }
                            })
                        })
                    }
                }))
                setSchema(yup.object().shape(validations))
                addLabels(fieldLabels, 'Fields')
            }
        }
    }, [templateLoading, templateData, setAvailableLocales, addLabels, locale, i18n])

    const getData = async (uuid: string) => {
        try {
            return (await axios.get(`/protocols/${uuid}`)).data
        } catch (_) {
            return {}
        }
    }

    useEffect(() => {
        (async () => {
            if (!schema || !uuid) return
            const r = await db.protocols.get(uuid)
            const serverSideData = !admin ? await getData(uuid) : {}
            if (r === undefined) {
                const p = {uuid, data: serverSideData, files:[]}
                db.protocols.add(p).catch(_ => {})
                setData(p)
            } else {
                setData({...r, data: {...r.data, ...serverSideData}})
            }
        })()
    }, [admin, schema, uuid])

    const {t} = useI18n()

    if (templateLoading || !data) {
        return <div>{t('pleaseWait')}</div>
    } else if (!schema) {
        return <Container className="p-3">
            <h1>{t('noProtocolFound.head')}</h1>
            <p>{t('noProtocolFound.copy')}</p>
        </Container>
    } else {
        return (
            <GeoLocationContextProvider>
                <FileUploadContextProvider uuid={uuid} admin={admin}>
                    <Protocol admin={admin} uuid={uuid!} def={templateData as FormDef} schema={schema} protocolData={data} />
                </FileUploadContextProvider>
            </GeoLocationContextProvider>
        )
    }
}

// @ts-ignore
const host = window.ReactNativeWebView || undefined

function getAllFields(hasFields: HasFields): FieldDef[] {
    return hasFields.fields ? hasFields.fields.filter(f => f.visibility !== 'PDF').reduce((product: FieldDef[], value) => {
        let arr
        if (value.fields) {
            arr = [value, ...product.concat(getAllFields(value))]
        } else if (value.radioOptions) {
            arr = [value, ...product.concat(value.radioOptions.filter(o => !!o.fields?.length).map(o => getAllFields(o)).flat(1))]
        } else {
            arr = product.concat(value)
        }
        return arr
    }, []) : []
}

function FormTab({tab, index, onSave, onNext, submittingData}: { onSave: () => void, onNext?: () => void, tab: TabDef, index: number, submittingData: boolean}) {
    const fields = useMemo(() => getAllFields(tab).map(f => f.name).filter(f => !!f), [tab])

    const { formState, trigger } = useFormContext()
    const [isValid, setIsValid] = useState<boolean | undefined>(undefined)
    const [touched, setTouched] = useState(false)
    const [saved, setSaved] = useState(false)

    useEffect(() => {
        if (isValid === undefined && !saved && fields.every(f => !get(formState.dirtyFields, f))) return
        setIsValid(fields.every(f => !get(formState.errors, f)))
    }, [fields, formState, isValid, saved])


    const { activeEventKey } = useContext(AccordionContext)

    useEffect(() => {
        if (touched && activeEventKey !== tab.key) {
            trigger(fields).then(setIsValid)
        }
    }, [activeEventKey, fields, tab.key, touched, trigger])

    const handleClick = (_: string, isOpen: boolean) => {
        if (isOpen && !touched) {
            setTouched(true)
        }
    }

    const doSave = () => {
        onSave()
        setSaved(true)
        setTouched(true)
        onNext && onNext()
    }

    const {t} = useI18n()
    const title = tab.title ? t(tab.title, {_: tab.title}) : t('Tabs.' + tab.key)

    return (
        <Accordion.Item eventKey={tab.key}>
            <AccordionHeader isValid={isValid} title={`${index + 1}. ${title}`} eventKey={tab.key} onClick={handleClick} />
            <Accordion.Body>
                {tab.fields!.filter(f => f.visibility !== 'PDF').map(f =>
                    <Field field={f} key={f.name || Math.random().toString()} />
                )}
                <hr/>
                <div className="clearfix">
                    <Button className="float-end" variant="secondary" disabled={submittingData} onClick={doSave}>{t('save' + (onNext ? 'AndNext' : ''))}</Button>
                </div>
            </Accordion.Body>
        </Accordion.Item>
    )
}

function Protocol({admin, uuid, def, schema, protocolData}: {admin: boolean, uuid:string, def:FormDef, schema:any, protocolData:ProtocolData}) {
    // @ts-ignore
    const hasNativeAppUploadSupport = protocolData.data.allowDelayedUpload && host && window.window.__hasProtocolUploadSupport

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: protocolData.data || {}
    })

    const { register, getValues, formState, watch, trigger, } = methods

    const navigate = useNavigate()

    const submitConfig: AxiosRequestConfig = admin ? {url: `/admin/protocols/testSubmit`, headers: {'Authorization': `Bearer ${uuid}`}} : {url: `/protocols/${uuid}/submit`}

    const [{loading:submittingData}, submitData] = useAxios({...submitConfig, method:'PUT'}, {manual:true})

    const {t} = useI18n()

    const licencePlateCountry = watch('licencePlateCountry')

    useEffect(() => {
        if (!!licencePlateCountry && !!getValues('licencePlate')) {
            trigger('licencePlate')
        }
    }, [licencePlateCountry, getValues, trigger])

    const saveToDb = async () => {
        const data = getValues()
        await db.protocols.update(uuid, {
            data: data,
            files: await Promise.all(uploadedFiles.map(async (f: UploadedFile) => ({
                uuid: f.uuid,
                blob: await f.blob.arrayBuffer(),
                type: f.blob.type
            })))
        })
        return data
    }

    const showSubmissionError = () => {
        setSaving(false)
        setTimeout(() => {
            alert(t('submit.failed'))
        }, 100)
    }

    const [saving, setSaving] = useState(false)
    const [showAppUpload, setShowAppUpload] = useState(false)
    const [appUpload, setAppUpload] = useState(false)

    useEffect(() => {
        if (!showAppUpload) {
            setAppUpload(false)
        }
    }, [showAppUpload])

    const onSubmit = async () => {
        setSaving(true)
        const geoPos = await getCurrentPosition()
        host?.postMessage('protocolSubmitting')
        let data = {...await saveToDb(), protocolUuid: uuid, protocolName: def.name, geoPosition: geoPos ? {latitude: geoPos.coords.latitude, longitude: geoPos.coords.longitude} : null}
        try {
            let filesUploaded: boolean = false
            if (appUpload) {
                if (hasNativeAppUploadSupport) {
                    const files = await Promise.all(uploadedFiles.map(async (f: UploadedFile) => ({uuid: f.uuid, type: f.blob.type, data: await blobToBase64(f.blob)})))
                    const urlPrefix = axios.defaults.baseURL?.startsWith('http') ? '' : `${window.location.protocol}//${window.location.hostname}`
                    const url = `${urlPrefix}${axios.defaults.baseURL}/protocols/${uuid}/appUpload`
                    host?.postMessage('filesDump' + JSON.stringify({uuid, url, files}))
                    const checksums = files.map(f => md5(f.data))
                    data = {...data, delayedAppUpload: true, checksums}
                    filesUploaded = true
                }
            } else {
                filesUploaded = await uploadFiles();
            }
            if (filesUploaded) {
                const r = await submitData({data})
                if (r?.status / 100 === 2) {
                    if (admin) {
                        navigate(`/protocols/admin/${uuid}/done`)
                    } else {
                        db.protocols.delete(uuid).catch(_ => {})
                        host?.postMessage('protocolSubmitted')
                        navigate(`/protocols/${uuid}/done?appUpload=${appUpload}`)
                    }
                } else {
                    showSubmissionError()
                }
            } else {
                showSubmissionError()
            }
        } catch (_) {
            showSubmissionError()
        }
    }

    useEffect(() => {
        register('milageMax')
        register('requiredLocation')
        register('outsideRequiredLocation')
    }, [register])

    const {ready: uploadReady, uploadFiles, progress, uploadedFiles} = useFileUploadContext()

    useEffect(() => {
        if (host && !uploadReady) {
            host?.postMessage(JSON.stringify({type:'protocolUploadProgress', progress}))
        }
    }, [progress, uploadReady])

    const onSave = async () => {
        setSaving(true)
        await saveToDb()
        setSaving(false)
    }

    const [agb, setAgb] = useState(false)
    const [activeKey, setActiveKey] = useState<AccordionEventKey>()
    const readyToSubmit = formState.isValid && agb
    return (
        <Container className="p-3 mb-4">
            <MyForm onSubmit={onSubmit} methods={methods}>
                <h1>{t(def.headline, {_: def.headline})}</h1>
                <p className="text-justify">{def.intro && t(def.intro, {_: def.intro})}</p>
                <Accordion activeKey={activeKey} onSelect={e => setActiveKey(e)}>
                    {def.tabs.map((t, idx) =>
                        <FormTab key={`tab_${t.key}`} tab={t} index={idx} submittingData={saving} onSave={onSave} onNext={idx < (def.tabs.length - 1) ? () => setActiveKey(def.tabs[idx + 1].key) : () => setActiveKey('__NO_TAB_OPENDED__')} />
                    )}
                </Accordion>
                <hr/>

                <Form.Check id="__checkbox-id-agb">
                    <Form.Check.Input checked={agb} onChange={(e: ChangeEvent<HTMLInputElement>) => setAgb(e.currentTarget.checked)} />
                    <Form.Check.Label><p dangerouslySetInnerHTML={{ __html: def.submit?.copy || t('submit.legal')}} /></Form.Check.Label>
                </Form.Check>

                <div>
                    {!admin && <>
                        <Button size="lg" variant="primary" disabled={admin || saving || submittingData || !readyToSubmit} type="submit">{(!uploadReady || submittingData || saving) ? t('submit.pleaseWait') : !formState.isValid ? t('submit.notValid') : (def.submit?.label || t('submit.label'))}</Button>
                    </>}

                    {hasNativeAppUploadSupport && <div className="mt-2 mb-4">
                        <Form.Check id="__checkbox-id-showAppUpload">
                            <Form.Check.Input disabled={!readyToSubmit} checked={showAppUpload} onChange={(e: ChangeEvent<HTMLInputElement>) => setShowAppUpload(e.currentTarget.checked)} />
                            <Form.Check.Label>{t('submit.appUpload.show')}</Form.Check.Label>
                        </Form.Check>
                        { showAppUpload && <div className="mt-2 alert alert-danger">
                            <p>{t('submit.appUpload.copy')}</p>
                            <Form.Check id="__checkbox-id-appUpload">
                                <Form.Check.Input checked={appUpload} onChange={(e: ChangeEvent<HTMLInputElement>) => setAppUpload(e.currentTarget.checked)} />
                                <Form.Check.Label>{t('submit.appUpload.check')}</Form.Check.Label>
                            </Form.Check>
                        </div>}
                    </div>}

                    {admin && <Button onClick={onSubmit} type="button" size="lg" variant="primary">Admin Submit</Button>}

                    <Modal show={!uploadReady || submittingData} size="lg">
                        <Modal.Body>
                            <h4 className="mb-4">{t('submit.uploading.head')}</h4>
                            <ProgressBar animated now={progress || 0} label={`${progress || 0}%`} />
                            <p className="mt-4">{t('submit.uploading.copy')}</p>
                        </Modal.Body>
                    </Modal>
                </div>

            </MyForm>
        </Container>
    )
}

function AccordionHeader({ title, eventKey, isValid, onClick }: {title: string, eventKey: string, isValid?: boolean, onClick?: (e: string, isOpen: boolean) => void}) {
    const { activeEventKey } = useContext(AccordionContext)

    return (
            <Accordion.Button onClick={_ => {
                onClick && onClick(eventKey, activeEventKey !== eventKey)
            }}>
                <h3>
                    {/*<FontAwesomeIcon icon={`caret-${eventKey === activeEventKey ? 'down' : 'right'}`} style={{width: '1.5em'}} />*/}
                    {title}
                    {isValid !== undefined && <FontAwesomeIcon icon={isValid ? 'check' : 'xmark'}  color={isValid ? '#28a745' : '#dc3545'} style={{marginLeft: '0.5em', display:'inline-block'}}/>}
                </h3>
            </Accordion.Button>
    )
}

const ImageUploader = ({name, max = 10}:{name:string, max:number}) => {
    const { control, register, setValue, getValues, errors, trigger, clearErrors, setError } = useFormContext()
    const { fields, append, remove } = useFieldArray({
        control,
        name
    })

    useEffect(() => {
        if (fields.length) {
            fields.forEach((f, index) => {
                // register(`${name}[${index}].dataUrl`)
                // setValue(`${name}[${index}].dataUrl`, f.dataUrl)
                register(`${name}[${index}].uuid`)
                setValue(`${name}[${index}].uuid`, f.uuid)
            })
        }
    },[fields, name, register, setValue])

    // @ts-ignore
    const nativeCameraSupport = host && (window.takePicture || undefined)

    const inputRef = useRef<HTMLInputElement | null>(null)
    const addImage = async (source?: 'camera' | 'gallery' | undefined) => {
        if (fields.length < max) {
            if (!nativeCameraSupport) {
                inputRef.current?.click()
            } else {
                // click body to hide popover...
                document.body.click()
                // @ts-ignore
                const img = await window.takePicture({fromCamera: source === 'camera', saveToCameraRoll: source === 'camera'})
                if (img) {
                    const dataStr = window.atob(img)
                    let n = dataStr.length
                    const dataArr = new Uint8Array(n)
                    while (n--) dataArr[n] = dataStr.charCodeAt(n)

                    doAddFiles([new File([dataArr], 'img.jpg', {type: 'image/jpeg'})])
                }
            }
        }
    }
    const fileContext = useFileUploadContext()

    const removeImage = (index: number, uuid: string) => {
        fileContext.removeFile(uuid)
        remove(index)
        trigger(name)
    }
    const onClick = (e:any) => {
        e.target.value = null
    }

    const [loading, setLoading] = useState(false)
    const MAX_SIZE = 50

    const {isNearLocation} = useGeoLocation()

    const doAddFiles = (files: File[]) => {
        if (files.some(f => f.size >= MAX_SIZE * 1024 * 1024)) {
            setError(name, { type: 'fileSize', message: t('validationError.fileSize', { maxSize: MAX_SIZE, unit: 'MB' }) })
            return
        }

        const reqLoc = getValues('requiredLocation')
        if (reqLoc && !isNearLocation(reqLoc, 2000)) {
            alert(t('validationError.locationCheckTooFarAway', {location:reqLoc.name}))
            setValue('outsideRequiredLocation', true)
            //return
        } else {
            setValue('outsideRequiredLocation', false)
        }

        setLoading(true)
        Promise.all(
            files
                .filter(f => f.size < MAX_SIZE * 1024 * 1024)
                .map(f => fileContext.addFile(f))
        ).then(fileData => {
            setLoading(false)
            append(fileData)
            clearErrors(name)
        }).catch(err => {
            setLoading(false)
            setError(name, { message: err.message })
        })
    }

    const onDrop = async (files: FileList | null) => {
        if (!files) return

        doAddFiles(Array.from(files))

    }

    const {t} = useI18n()
    const error = get(errors, name)

    let addImageButton: React.ReactNode

    if (nativeCameraSupport) {
        addImageButton =
            <OverlayTrigger trigger="click" placement="right" rootClose={true} rootCloseEvent="click" overlay={
                <Popover>
                    <Popover.Body>
                        <Button variant="link" size="lg" className="addImageOptions" onClick={() => addImage('camera')}><FontAwesomeIcon
                            size="2x" icon="camera"/></Button>
                        <Button variant="link" size="lg" className="addImageOptions" onClick={() => addImage('gallery')}><FontAwesomeIcon
                            size="2x" icon="images"/></Button>
                    </Popover.Body>
                </Popover>}>
                <Button variant="link" className="addImage"><FontAwesomeIcon icon="plus-circle"/></Button>
            </OverlayTrigger>;
    } else {
        addImageButton = <Button variant="link" className="addImage" onClick={() => addImage()}><FontAwesomeIcon icon="plus-circle"/></Button>
    }

    const [dragging, setDragging] = useState(false)
    return (
        <div onDragOver={e => {e.preventDefault(); setDragging(true)}}
             onDragLeave={e => {e.preventDefault(); setDragging(false)}}
             onDrop={e => {e.preventDefault();setDragging(false);(e.dataTransfer?.files?.length > 0) && onDrop(e.dataTransfer.files)}}>
            <div className={`list-of-groups image-uploader-container ${dragging ? 'active' : ''} ${error ? 'is-invalid' : ''}`}>
                {fields.map((item, index) => (
                    <div key={item.id} className="image-uploader-preview">
                        <Button variant="link" size="lg" onClick={() => removeImage(index, item.uuid)}><FontAwesomeIcon icon="times-circle" /></Button>
                        <img src={fileContext.getUrlFor(item.uuid)} alt={t('emptyImageAltText')} />
                    </div>
                ))}
                {(fields.length < max) && addImageButton}
                <input multiple={max > 1} accept="image/*" onDrop={e => onDrop(e.currentTarget.files)} onChange={e => onDrop(e.currentTarget.files)} onClick={onClick} type="file" style={{opacity:0, position:'absolute',zIndex:-1}} ref={inputRef} />
            </div>
            {loading && <div>
                <Spinner animation="border" variant="secondary" className="align-middle ml-3" /> {t('pleaseWaitWhileUpload')}
            </div>}
            {error && <Form.Control.Feedback type="invalid">
                {!!error.message.key ? t(error.message.key, error.message.params) : error.message}
            </Form.Control.Feedback>}
        </div>
    )
}

function Marker({name, initialPos, label, boundingBoxRef}:any) {
    const {register, setValue} = useFormContext()
    const {targetRef, pos, dragging} = useDraggable(initialPos, boundingBoxRef)

    useEffect(() => {
        register(`${name}`)
    }, [name, register])

    useEffect(() => {
        setValue(`${name}`, pos, {shouldDirty: true})
    }, [name, pos, setValue])

    return (
        <div>
            <div ref={targetRef} style={{marginLeft:"-25px",marginTop:"-25px",width:"50px",height:"50px",border:"6px solid red", borderRadius:1000}}>
            <span style={{opacity:(dragging ? 0.1 : 1), cursor:"crosshair",fontSize:"24px", fontWeight:"bold", color:"red"}}>{label}</span>
            </div>
        </div>
    )
}

const Damage = ({field:f}: FieldProps) => {
    const { control, errors, trigger, clearErrors } = useFormContext()
    const name = `${f.name}.damages`
    const { fields, append, remove } = useFieldArray({
        control,
        name,
        keyName:'key'
    })

    useEffect(() => {
        trigger(name)
        return () => {
            remove()
            trigger(name)
        }
    }, [name, remove, trigger])

    const addDamage = (d:any) => {
        append(d, false)
        clearErrors(name)
    }
    const removeDamage = (index: number) => {
        remove(index)
        trigger(name)
    }

    const boundingBoxRef = useRef<HTMLDivElement|null>(null)

    const {t, getEffectiveLabel} = useI18n()
    const error = get(errors, name)

    return (
        <div className="mb-4">
            <div className={`list-of-groups ${error?.message && 'is-invalid'}`}>
                <h3>{getEffectiveLabel(f.label, 'Fields.' + f.name)}</h3>
                {!f.noDamageTemplate && <>
                    <div className="mt-4 mb-4 row justify-content-center">
                        <div ref={boundingBoxRef} style={{
                            position: "relative",
                            textAlign: "center",
                            width: "800px",
                            margin: "0 10px 0 10px",
                            height: "auto",
                            border: "1px solid red"
                        }}>
                            <img src={template} alt="Template" style={{width: "100%"}}/>
                            {fields.map((item, index) =>
                                <Marker key={`${item.key}`} name={`${name}[${index}].pos`} initialPos={item.pos}
                                        label={`${(index + 1)}`} boundingBoxRef={boundingBoxRef}/>
                            )}
                            <Button style={{position: "absolute", width: "80px", bottom: "10px", right: "10px"}}
                                    size="lg" variant="secondary"
                                    onClick={() => addDamage({pos: {x: 0.5, y: 0.5}})}><FontAwesomeIcon
                                icon="plus-circle"/></Button>
                        </div>
                    </div>
                </>}

                {fields.map((item, index) => (
                    <div key={`${item.key}`}>
                        <h3>
                            {t('Damages.listHead', {idx: index + 1})}
                            <Button className="ms-2" size="sm" onClick={() => removeDamage(index)}><FontAwesomeIcon
                                icon="times-circle"/></Button>
                        </h3>
                        <FormGroup>
                            <Form.Label>{getEffectiveLabel(f.damageKindLabel, 'Damages.kind')}</Form.Label>
                            <FormField type={Select} name={`${name}[${index}].kind`} defaultValue={item.kind || ''}
                                       options={f.damageOptions?.map(value => ({
                                           value,
                                           label: t('Options.' + value, {_: value}),
                                       }))}/>
                        </FormGroup>
                        {!!f.damageParts && f.damageParts.length > 0 &&
                            <FormGroup>
                                <Form.Label>{getEffectiveLabel(f.damagePartLabel, 'Damages.part')}</Form.Label>
                                <FormField type={Select} name={`${name}[${index}].part`} defaultValue={item.part || ''}
                                           options={f.damageParts.map(value => ({
                                               value,
                                               label: t('Options.' + value, {_: value}),
                                           }))}/>
                            </FormGroup>
                        }
                        <FormGroup>
                            <Form.Label>{getEffectiveLabel(f.damageNotesLabel, 'Damages.notes')}</Form.Label>
                            <FormField type={Textarea} name={`${name}[${index}].notes`}
                                       defaultValue={item.notes || ''}/>
                        </FormGroup>
                        <FormGroup>
                            <Form.Label>{getEffectiveLabel(f.damageImagesLabel, 'Damages.images')}</Form.Label>
                            <ImageUploader name={`${name}[${index}].images`} max={10}/>
                        </FormGroup>
                    </div>
                ))}
                {!!f.noDamageTemplate && <Button variant="secondary" onClick={() => addDamage({})}><FontAwesomeIcon
                    icon="plus-circle"/> {t('Damages.add')}</Button>}
            </div>
            {error?.message &&
                <Form.Control.Feedback type="invalid">
                {t(error.message.key, error.message.params)}
            </Form.Control.Feedback>
            }
        </div>
    )
}


const SignatureBox = ({field:f}: FieldProps) => {
    const {register, getValues, setValue, errors} = useFormContext()

    const ref = useRef<SignatureRef | null>(null)

    useEffect(() => {
        register(f.name)
        getValues(f.name) || setValue(f.name, '')
        ref.current!.setImage(getValues(f.name))
    }, [f.name, setValue, getValues, register])


    const {t, getEffectiveLabel} = useI18n()
    const label = getEffectiveLabel(f.label, 'Fields.' + f.name)
    const error = get(errors, f.name)

    return (
        <FormGroup>
            <Form.Label>{label}</Form.Label>
            <Signature ref={ref} onChange={canvas => setValue(f.name, canvas?.toDataURL("image/png") || '', {shouldValidate:true, shouldDirty:true})} />
            {error?.message &&
            <Form.Control.Feedback type="invalid">
                {t(error.message.key, error.message.params)}
            </Form.Control.Feedback>}
        </FormGroup>
    )
}

const Upload = ({field:f}: FieldProps) => {
    const {getEffectiveLabel} = useI18n()
    const label = getEffectiveLabel(f.label,'Fields.' + f.name)
    return (
        <FormGroup>
            {label && <Form.Label>{label}</Form.Label>}
            <ImageUploader name={`${f.name}.images`} max={f.maxImages || 10} />
        </FormGroup>
    )
}

interface FieldProps {
    field: FieldDef
}

const TYPES = {
    text: undefined,
    textUppercase: TextUppercase,
    textarea: Textarea,
    checkbox: CheckBox,
    date: DateInput,
    datetime: DateTimeInput,
    integer: IntegerInput,
    decimal: DecimalInput,
    select: Select,
    radios: RadioBoxes,
    radiosNested: RadioBoxes,
    checkboxes: CheckBoxes,
}

const Field = ({field:f}: FieldProps) => {
    const { watch, register } = useFormContext()

    const {t, getEffectiveLabel} = useI18n()

    useEffect(() => {
        if (f.readonly) {
            register(f.name)
        }
    }, [f, register])

    const getOptions = (f: FieldDef) => {
        if (f.radioOptions) {
            return f.radioOptions.map(({value, label}) => ({value, label: label || t('Options.' + value, {_: value})}))
        } else if (f.options) {
            if (typeof f.options[0] === 'object') {
                return f.options?.map(({value, label}: any) => ({value, label: label || t('Options.' + value, {_: value})}))
            } else {
                return f.options?.map((value: any) => ({value, label: t('Options.' + value, {_: value})}))
            }
        } else {
            return undefined
        }
    }

    const watched = f.showFieldsIf || f.radioOptions ? watch(f.name) : undefined
    const label = getEffectiveLabel(f.label, 'Fields.' + f.name)
    if (f.type === "headline") {
        return <><hr/><h3>{label}</h3></>
    } else if (f.type === "label") {
        return <Form.Label>{label}</Form.Label>
    } else if (f.type === 'copy') {
        return <p>{label}</p>
    } else if (f.type === 'damages') {
        return <Damage field={f} />
    } else if (f.type === 'signature') {
        return <SignatureBox field={f} />
    } else if (f.type === 'upload') {
        return <Upload field={f} />
    } else {
        const addProps: any = {}
        if (f.type === 'date' || f.type === 'datetime') {
            addProps.allowFutureDates = f.allowFutureDates
            addProps.minDate = f.minDate ? new Date(watch(f.minDate)) : undefined
        }
        const radioOption = f.radioOptions && f.radioOptions.find(o => o.value === watched)

        return <>
            <FormGroup key={f.name}>
                {!!label && <Form.Label>{label}</Form.Label>}
                <div>
                    <FormField type={TYPES[f.type]} name={f.name} placeholder={f.placeholder || label} label={f.type === 'checkbox'? (f.checkboxLabel || t('Checkboxes.' + f.name)) : undefined} append={f.append} options={getOptions(f)} inline={f.inline || undefined} readOnly={!!f.readonly} {...addProps} />
                </div>
                {f.help && <Form.Text muted>{f.help}</Form.Text>}
            </FormGroup>
            {f.showFieldsIf && `${watched}` === `${f.showFieldsIf}` && f.fields && f.fields!.filter(f => f.visibility !== 'PDF').map(ff => <Field field={ff} key={`${f.name}_${ff.name}`} />)}
            {radioOption && radioOption.fields?.filter(f => f.visibility !== 'PDF').map(ff => <Field field={ff} key={`${f.name}_${ff.name}`} />)}
        </>
    }
}
export default ProtocolScreen
